import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Navigate, useNavigate } from "react-router-dom";
import Fab from "@mui/material/Fab";
import moment from "moment";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Meeting() {
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const [meetingList, setMeetingList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["email"] = state.auth.user.email;
    body["class_id"] = state.auth.user.class_id;
    body["division"] = state.auth.user.division;
    body["institution_id"] = state.auth.user.institution_id;

    body = JSON.stringify(body);

    axios.post(API_URL + "/get_meetings_student", body, config).then((res) => {
      setMeetingList(res.data);
      setIsLoading(false);
    });
  }

  function getTimeInWords(time) {
    var ampm, hour, minutes, fullTime;

    hour = time.getHours();

    if (hour <= 11) {
      ampm = "AM";
    } else {
      ampm = "PM";
    }

    if (hour > 12) {
      hour = hour - 12;
    }

    if (hour == 0) {
      hour = 12;
    }

    minutes = time.getMinutes();

    if (minutes < 10) {
      minutes = "0" + minutes.toString();
    }

    fullTime =
      hour.toString() + ":" + minutes.toString() + " " + ampm.toString();

    return fullTime;
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    getData();
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_student) {
        if (moment(state.auth.expiry).utc().isAfter(moment())) {
          if (isLoading) {
            return (
              <Fragment>
                <Sidebar />
                <div
                  style={{
                    maxWidth: "88vw",
                    marginTop: "80px",
                    marginLeft: "8vw",
                    backgroundColor: "white",
                    height: "100vh",
                  }}
                >
                  <Grid spacing={0} alignItems="center" justifyContent="center">
                    <Box>
                      <CircularProgress />
                    </Box>
                  </Grid>
                </div>
              </Fragment>
            );
          } else {
            return (
              <Fragment>
                <Sidebar />
                {meetingList.length == 0 ? (
                  <div
                    style={{
                      marginTop: "200px",
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Fab
                        size="large"
                        style={{
                          border: "3px solid #aaa",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                        aria-label="add"
                      >
                        <PriorityHighIcon
                          style={{
                            color: "grey",
                          }}
                        />
                      </Fab>
                      <br />
                      <h4 style={{ color: "grey" }}>No upcoming meetings.</h4>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      margin: "65px 15px 20px 90px",
                      backgroundColor: "white",
                    }}
                  >
                    <h3 style={{ marginTop: "-5px" }}>Meetings</h3>
                    <Grid
                      container
                      alignItems="center"
                      p={0}
                      spacing={2}
                      style={{
                        fontFamily: "Bahnschrift",
                        marginTop: "-10px",
                        fontSize: "15px",
                      }}
                    >
                      <Grid item md={2}>
                        <b style={{ marginLeft: "10px" }}> Teacher</b>
                      </Grid>

                      <Grid item md={2}>
                        <b style={{ marginLeft: "10px" }}> Topic</b>
                      </Grid>
                      <Grid item md={5}>
                        <b> Link</b>
                      </Grid>
                      <Grid item md={1}>
                        <b> Start</b>
                      </Grid>
                      <Grid item md={1}>
                        <b> End</b>
                      </Grid>
                      <Grid item md={1}>
                        <b> Status</b>
                      </Grid>
                    </Grid>
                    <br></br>
                    {meetingList.map((meeting) => (
                      <Box
                        p={2}
                        style={{
                          marginBottom: "20px",
                          width: "100%",
                          minHeight: "20px",
                          borderRadius: "6px",
                          backgroundColor: "white",
                          border: "2px solid #d7ecff",
                          color: "black",
                        }}
                      >
                        <Grid container alignItems="center">
                          <Grid item md={2}>
                            {meeting["teacher"]}
                          </Grid>

                          <Grid item md={2}>
                            {meeting["meeting_name"]}
                          </Grid>
                          <Grid item md={5}>
                            <div
                              style={{
                                paddingRight: "20px",
                                width: "100%",
                              }}
                            >
                              <b style={{ color: "blue" }}>
                                <a
                                  style={{
                                    textDecoration: "none",
                                    fontSize: "12px",
                                  }}
                                  href={meeting["meet_link"]}
                                  target="_blank" rel="noreferrer"
                                >
                                  {meeting["meet_link"]}
                                </a>
                              </b>
                            </div>
                          </Grid>
                          <Grid item md={1}>
                            {meeting["time_start"]}
                          </Grid>
                          <Grid item md={1}>
                            {meeting["time_end"]}
                          </Grid>
                          <Grid item md={1}>
                            {meeting["meeting_finish"] == true ? (
                              <span
                                style={{
                                  textAlign: "center",
                                  marginLeft: "15px",
                                }}
                              >
                                Finished
                              </span>
                            ) : (
                              <span
                                style={{
                                  textAlign: "right",
                                  marginLeft: "15px",
                                }}
                              >
                                Scheduled
                              </span>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                  </div>
                )}
              </Fragment>
            );
          }
        } else {
          return <Navigate to="/expired" />;
        }
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Student");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Meeting;
