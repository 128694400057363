import React, { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import Button from "@mui/material/Button";
import GetAppIcon from "@mui/icons-material/GetApp";
import TextField from "@mui/material/TextField";
import PublishIcon from "@mui/icons-material/Publish";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import ButtonBase from "@mui/material/ButtonBase";
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { formatISO } from "date-fns";
import moment from "moment";
import Fab from "@mui/material/Fab";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

import DeleteIcon from "@mui/icons-material/Delete";

const colorList = ["#e5f5e0", "#d7ecff", "#fee6ce"];
const colorList2 = ["#31a354", "#3283c9", "#e6550d"];
const colorList3 = ["#addd8e", "#9ecae1", "#a1d99b"];

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Classes() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "NOTES"
  );
  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  if (state.auth.refresh == "REFRESH_DATA") {
    getData();
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  const [leaveRequestList, setLeaveRequestList] = useState([]);
  const [assignmentList, setAssignmentList] = useState([]);
  const [notesList, setNotesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [leaveSubject, setLeaveSubject] = useState("");
  const [leaveDescription, setLeaveDescription] = useState("");
  const [assignmentId, setAssignmentId] = useState("");
  const [assignmentLink, setAssignmentLink] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [teacherEmail, setTeacherEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [deleteItemClass, setDeleteItemClass] = useState("");
  const [deleteItemDivision, setDeleteItemDivision] = useState("");

  const [selectedFile, setSelectedFile] = useState("");
  const [id, setId] = useState("");

  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [classs, setClass] = useState("");
  const [division, setDivision] = useState("");

  const [open, setOpen] = useState(false);
  const [open3, setOpen3] = useState(false);

  function getSize(fileSize) {
    if (fileSize < 1024) {
      return <p>Size: {(fileSize / 1024).toFixed(2)} B</p>;
    } else if (fileSize > 1024 && fileSize < 1024 * 1024) {
      return <p>Size: {(fileSize / (1024 * 1024)).toFixed(2)} KB</p>;
    } else if (fileSize > 1024 * 1024 && fileSize < 25 * 1024 * 1024) {
      return <p>Size: {(fileSize / (1024 * 1024)).toFixed(2)} MB</p>;
    } else if (fileSize > 25 * 1024 * 1024) {
      setOpen(false);
      createAlert(
        "ERROR",
        "File too big.",
        "File size should be less than 25 MB."
      );
    }
  }

  function fileData() {
    if (selectedFile) {
      return (
        <div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton size="small">
              <CloseIcon
                onClick={() => setOpen(false)}
                style={{ color: "#3182bd" }}
              />
            </IconButton>
          </div>
          <h2>File Details:</h2>
          <p>File Name: {selectedFile.name}</p>
          <p>File Type: {selectedFile.type}</p>

          <br></br>
          <br></br>
          {selectedFile.size > 25 * 1024 * 1024 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => {
                  createAlert(
                    "ERROR",
                    "File too big.",
                    "File size should be less than 25 MB."
                  );
                  setOpen(false);
                }}
                variant="contained"
                style={{ backgroundColor: "#ddd" }}
              >
                <PublishIcon />
                &nbsp;&nbsp; UPLOAD DOCUMENTS
              </Button>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                onClick={() => onFileUpload()}
                color="primary"
              >
                <PublishIcon />
                &nbsp;&nbsp; UPLOAD DOCUMENTS
              </Button>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  }

  function onFileUpload() {
    setIsSubmitting(true);
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedFile, selectedFile.name);

    // Details of the uploaded file
    console.log(selectedFile);

    // Request made to the backend api
    // Send formData object

    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        // "Content-Type": "application/json",
      },
    };

    var date = new Date();
    formData.append("name", name);
    formData.append("email", state.auth.user.email);
    formData.append("subject", subject);
    formData.append("description", description);
    formData.append("institution_id", state.auth.user.institution_id);
    formData.append("current_year", state.auth.user.current_year);

    formData.append("submission_date", moment(date).format("DD-MM-YYYY"));

    formData.append("date", moment(selectedDate).format("DD-MM-YYYY"));
    formData.append("class_id", classs);
    formData.append("division", division);

    if (selectedPage == "ASSIGNMENTS") {
      formData.append("id", assignmentId);
      formData.append("teacher_email", teacherEmail);
      formData.append("student_email", state.auth.user.email);

      axios
        .post(API_URL + "/submit_assignment", formData, config)
        .then(() => {
          setIsSubmitting(false);
          getData();
          createAlert("SUCCESS", "Success.", "Assignment Submitted.");
        })
        .catch((err) => {
          console.log("___");
          console.log(err);
          createAlert("ERROR", "Error", "An error occured.");
        });
    } else if (selectedPage == "LEAVEREQUESTS") {
      formData.append("id", makeid(5));
      formData.append("leave_subject", leaveSubject);
      formData.append("leave_description", leaveDescription);

      axios
        .post(API_URL + "/add_leave_request", formData, config)
        .then(() => {
          setIsSubmitting(false);
          getData();
          createAlert("SUCCESS", "Success.", "Leave request submitted.");
        })
        .catch((err) => {
          console.log("___");
          console.log(err);
          createAlert("ERROR", "Error", "An error occured.");
        });
    }

    setOpen(false);
    document.getElementById("contained-button-file").value = "";
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  function onFileChange(event) {
    // Update the state
    setSelectedFile(event.target.files[0]);

    setOpen(true);
  }

  function onFileClick(event) {
    // Update the state
    document.getElementById("contained-button-file").value = "";
  }

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["institution_id"] = state.auth.user.institution_id;
    body["class_id"] = state.auth.user.class_id;
    body["division"] = state.auth.user.division;
    body["email"] = state.auth.user.email;
    body["current_year"] = state.auth.current_year;

    body = JSON.stringify(body);

    axios
      .post(API_URL + "/get_notes_list_student", body, config)
      .then((res) => {
        setNotesList(res.data);

        axios
          .post(API_URL + "/get_assignment_list_student", body, config)
          .then((res) => {
            axios
              .post(API_URL + "/get_leave_requests", body, config)
              .then((res) => {
                setLeaveRequestList(res.data);
              });
            setIsLoading(false);

            setAssignmentList(res.data);
          });
      });
  }

  function deleteItem() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    body["id"] = id;
    body = JSON.stringify(body);
    axios.post(API_URL + "/delete_leave_request", body, config).then((res) => {
      getData();
      setOpen2(false);
    });
  }

  function selectedPageContent() {
    if (selectedPage == "NOTES") {
      return (
        <Fragment>
          {notesList.length == 0 ? (
            <div
              style={{
                marginTop: "200px",
                height: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Fab
                  size="large"
                  style={{
                    border: "3px solid #aaa",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  }}
                  aria-label="add"
                >
                  <PriorityHighIcon
                    style={{
                      color: "grey",
                    }}
                  />
                </Fab>
                <br />
                <h4 style={{ color: "grey" }}>No Notes uploaded.</h4>
              </div>
            </div>
          ) : (
            ""
          )}
          <Grid container spacing={1.5}>
            {notesList.map((note) => (
              <Fragment>
                <Grid item md={3}>
                  <a
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    href={note["link"]} rel="noreferrer"
                  >
                    <ButtonBase
                      style={{
                        minHeight: "100%",
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        borderRadius: "6px",
                        backgroundColor: "#3182bd",
                        color: "white",
                        textAlign: "left",
                        padding: "10px 5px 5px 5px",
                        justifyContent: "space-between",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <div style={{ margin: "0px 5px 5px 5px" }}>
                        <span
                          style={{
                            fontSize: "16px",
                            fontFamily: "Ubuntu Medium",
                          }}
                        >
                          {note["name"]}
                        </span>
                        <br></br>
                        <span
                          style={{
                            fontSize: "13px",
                            fontFamily: "Roboto-Regular",
                          }}
                        >
                          {note["subject"]}
                        </span>
                        <div
                          style={{
                            height: "0.5px",
                            width: "100%",
                            backgroundColor: "white",
                            margin: "10px 0px 5px 0px",
                          }}
                        ></div>
                        <div
                          style={{
                            fontSize: "13px",
                            marginTop: "10px",
                            fontFamily: "Lato-Regular",
                          }}
                        >
                          {note["description"]}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "5px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "13px",
                            marginLeft: "5px",
                            fontFamily: "Lato-Bold",
                          }}
                        >
                          {moment(note["date"], "DD-MM-YYYY").format(
                            "Do MMMM, YYYY"
                          )}
                        </div>
                        <Box textAlign="right">
                          <a
                            style={{ color: "white" }}
                            target="_blank"
                            href={note["link"]} rel="noreferrer"
                          >
                            <GetAppIcon />
                          </a>
                        </Box>
                      </div>
                    </ButtonBase>
                  </a>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Fragment>
      );
    } else if (selectedPage == "ASSIGNMENTS") {
      return (
        <Fragment>
          {assignmentList.length == 0 ? (
            <div
              style={{
                marginTop: "200px",
                height: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Fab
                  size="large"
                  style={{
                    border: "3px solid #aaa",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  }}
                  aria-label="add"
                >
                  <PriorityHighIcon
                    style={{
                      color: "grey",
                    }}
                  />
                </Fab>
                <br />
                <h4 style={{ color: "grey" }}>No assignments uploaded.</h4>
              </div>
            </div>
          ) : (
            ""
          )}

          <Grid container spacing={1.5}>
            {assignmentList.map((assignment, index) => (
              <Fragment>
                <Grid item md={3}>
                  <ButtonBase
                    onClick={(e) => {
                      setIsSubmitted(
                        assignment["completion_link"] ? true : false
                      );

                      setAssignmentLink(assignment["link"]);
                      setAssignmentId(assignment["id"]);
                      setTeacherEmail(assignment["teacher_email"]);
                      setOpen3(true);
                    }}
                    style={{
                      minHeight: "100%",
                      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                      borderRadius: "6px",
                      backgroundColor: assignment["completion_link"]
                        ? moment(
                            assignment["submission_date"],
                            "DD-MM-YYYY"
                          ).isAfter(
                            moment(assignment["completion_date"], "DD-MM-YYYY"),
                            "day"
                          )
                          ? "#ff3333"
                          : "#31a354"
                        : "#3182bd",
                      color: "white",
                      textAlign: "left",
                      padding: "10px 5px 5px 5px",
                      justifyContent: "space-between",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    <div style={{ margin: "0px 5px 5px 5px" }}>
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Ubuntu Medium",
                        }}
                      >
                        {assignment["name"]}
                      </span>
                      <br></br>
                      <span
                        style={{
                          fontSize: "13px",
                          fontFamily: "Roboto-Regular",
                        }}
                      >
                        {assignment["subject"]}
                      </span>
                      <div
                        style={{
                          height: "0.5px",
                          width: "100%",
                          backgroundColor: "white",
                          margin: "10px 0px 5px 0px",
                        }}
                      ></div>
                      <div
                        style={{
                          fontSize: "13px",
                          marginTop: "10px",
                          fontFamily: "Lato-Regular",
                        }}
                      >
                        {assignment["description"]}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "5px",
                        alignItems: "center",
                        marginBottom: "3px",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            fontSize: "11px",
                            marginLeft: "5px",
                            fontFamily: "Lato-Bold",
                          }}
                        >
                          Submission:
                          <span
                            style={{
                              fontFamily: "Lato-Regular",
                              marginLeft: "15px",
                            }}
                          >
                            {" "}
                            {"      " +
                              moment(
                                assignment["completion_date"],
                                "DD-MM-YYYY"
                              ).format("Do MMMM, YYYY")}
                          </span>
                        </div>
                        {assignment["completion_link"] ? (
                          <div
                            style={{
                              fontSize: "11px",
                              marginLeft: "5px",
                              fontFamily: "Lato-Bold",
                            }}
                          >
                            Submitted on:
                            <span
                              style={{
                                fontFamily: "Lato-Regular",
                                marginLeft: "5px",
                              }}
                            >
                              {"   " +
                                moment(
                                  assignment["submission_date"],
                                  "DD-MM-YYYY"
                                ).format("Do MMMM, YYYY")}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div style={{ display: "flex" }}>
                        <Tooltip title="Download assignment">
                          <Box textAlign="right">
                            <a
                              style={{ color: "white" }}
                              target="_blank"
                              href={assignment["link"]} rel="noreferrer"
                            >
                              <GetAppIcon
                                style={{
                                  marginTop: "1px",
                                }}
                              />
                            </a>
                          </Box>
                        </Tooltip>
                        {assignment["completion_link"] ? (
                          <Tooltip title="Submitted!">
                            <Box>
                              <CheckCircleIcon
                                style={{
                                  marginLeft: "3px",
                                  marginRight: "3px",

                                  cursor: "pointer",
                                }}
                              />
                            </Box>
                          </Tooltip>
                        ) : isSubmitting && assignment["id"] == assignmentId ? (
                          <CircularProgress
                            style={{
                              color: "white",
                              height: "20px",
                              width: "20px",
                              marginRight: "5px",
                              marginBottom: "5px",
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </ButtonBase>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Fragment>
      );
    } else if (selectedPage == "LEAVEREQUESTS") {
      return (
        <div style={{ marginTop: "100px" }}>
          <Grid container spacing={3} style={{ marginTop: "-50px" }}>
            <Grid item md={8}>
              {leaveRequestList.length == 0 ? (
                <div
                  style={{
                    marginTop: "200px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      size="large"
                      style={{
                        border: "3px solid #aaa",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "grey",
                        }}
                      />
                    </Fab>
                    <br />
                    <h4 style={{ color: "grey" }}>No Leave requests.</h4>
                  </div>
                </div>
              ) : (
                ""
              )}
              {leaveRequestList.map((leave, index) => (
                <Box
                  p={2}
                  style={{
                    height: "80px",
                    marginBottom: "15px",
                    borderRadius: "15px",
                    border:
                      leave["status"] == "PENDING"
                        ? "1.5px solid" + "#3182bd"
                        : "none",
                    backgroundColor:
                      leave["status"] == "PENDING"
                        ? "white"
                        : leave["status"] == "APPROVED"
                        ? colorList[0]
                        : colorList[2],
                    color: "black",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItemsL: "center",
                    }}
                  >
                    <div style={{ width: "90%" }}>
                      <div
                        style={{
                          margin: "-25px 0px 0px -3px",
                        }}
                      >
                        <h3
                          style={{
                            fontFamily: "Lato-Bold",
                            lineHeight: "20px",
                            color:
                              leave["status"] == "PENDING"
                                ? colorList2[1]
                                : leave["status"] == "APPROVED"
                                ? colorList2[0]
                                : colorList2[2],
                          }}
                        >
                          {leave["subject"]}
                        </h3>
                      </div>
                      <div
                        style={{
                          margin: "-15px 0px 0px -3px",
                          fontSize: "14px",
                        }}
                      >
                        {moment(leave["date"], "DD-MM-YYYY").format(
                          "Do MMMM, YYYY"
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {leave["status"] == "PENDING" ? (
                          <ButtonBase
                            style={{
                              cursor: "pointer",
                              marginLeft: "-10px",
                              display: "flex",
                              backgroundColor: "#ff3333",
                              borderRadius: "6px",
                              padding: "3px",
                              alignItems: "center",
                              justifyContent: "space-between",
                              position: "relative",
                              bottom: "62px",
                              height: "25px",
                              width: "25px",
                              right: "-105px",
                            }}
                            onClick={() => {
                              setId(leave["id"]);
                              setOpen2(true);
                            }}
                          >
                            <DeleteIcon
                              style={{ color: "white", fontSize: "18px" }}
                            />
                          </ButtonBase>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        width: "100px",
                        display: "flex",
                        justifyContent: "center",
                        marginTop:
                          leave["status"] == "PENDING" ? "-12px" : "-5px",
                        marginRight: "-15px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          textAlign="center"
                          p={1}
                          style={{
                            height: "35px",
                            width: "35px",
                            borderRadius: "60px",
                            backgroundColor:
                              leave["status"] == "PENDING"
                                ? colorList2[1]
                                : leave["status"] == "APPROVED"
                                ? colorList2[0]
                                : colorList2[2],
                            color: "white",
                          }}
                        >
                          {leave["status"] == "PENDING" ? (
                            <PriorityHighIcon
                              style={{
                                fontSize: "30px",
                                margin: "-5px 0px 0px -5.5px",
                              }}
                            />
                          ) : leave["status"] == "APPROVED" ? (
                            <CheckIcon
                              style={{
                                fontSize: "30px",
                                margin: "-5px 0px 0px -5.5px",
                              }}
                            />
                          ) : (
                            <CloseIcon
                              style={{
                                fontSize: "30px",
                                margin: "-5px 0px 0px -5.5px",
                              }}
                            />
                          )}
                        </Box>
                        <b
                          style={{
                            marginTop: "5px",
                            color:
                              leave["status"] == "PENDING"
                                ? colorList2[1]
                                : leave["status"] == "APPROVED"
                                ? colorList2[0]
                                : colorList2[2],
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Bahnschrift",
                              fontWeight: "lighter",
                            }}
                          >
                            {leave["status"]}
                          </span>
                        </b>
                      </div>
                    </div>
                  </div>
                </Box>
              ))}
            </Grid>
            <Grid item md={4}>
              <div style={{ marginTop: "-25px", marginBottom: "-15px" }}>
                <h3>Apply for Leave</h3>
              </div>
              <span
                style={{
                  color: "#3182bd",
                  fontFamily: "Lato-Regular",
                  fontSize: "14px",
                }}
              >
                Choose a date
              </span>
              <br></br>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                      inputFormat="dd-MM-yyyy"
                      value={selectedDate}
                      sx={{ height:"40px",  width:"100%", marginTop:"5px", marginBottom:"20px"}}
                      onChange={handleDateChange}
                      fullWidth

                      renderInput={(params) => (
                        <TextField
                          style={{
                            width:"100%",
                            marginTop: "2px",
                            marginBottom: "5px",
                            height:"40px",
                          }}
                          size="small"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
              </LocalizationProvider>
              <br></br>
              <span
                style={{
                  color: "#3182bd",
                  fontFamily: "Lato-Regular",
                  fontSize: "14px",
                }}
              >
                Leave Subject
              </span>
              <TextField
                value={leaveSubject}
                style={{ marginTop: "2px", marginBottom: "15px" }}
                onChange={(e) => setLeaveSubject(e.target.value)}
                fullWidth
                placeholder="Reason for leave"
                variant="outlined"
                size="small"
              />
              <span
                style={{
                  color: "#3182bd",
                  fontFamily: "Lato-Regular",
                  fontSize: "14px",
                }}
              >
                Leave Description
              </span>
              <TextField
                multiline={true}
                rows={4}
                value={leaveDescription}
                style={{ marginTop: "2px" }}
                onChange={(e) => setLeaveDescription(e.target.value)}
                fullWidth
                placeholder="Reason for leave"
                variant="outlined"
                size="small"
              />
              <br></br>
              <br></br>
              <div style={{}}>
                <label
                  style={{ width: "100%" }}
                  htmlFor="contained-button-file"
                >
                  <input
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    onChange={(e) => onFileChange(e)}
                  />

                  <Button
                    style={{
                      width: "100%",
                      fontSize: "15px",
                      height: "35px",
                      marginTop: "-1px",
                      backgroundColor: "#3182bd",
                    }}
                    variant="contained"
                    component="span"
                  >
                    <h4>Upload Request Application</h4>
                  </Button>
                </label>
                <Button
                  style={{
                    width: "100%",
                    fontSize: "15px",
                    height: "35px",
                    marginTop: "15px",
                    backgroundColor: "#3182bd",
                  }}
                  onClick={() => {
                    if (
                      leaveSubject.length == 0 ||
                      leaveDescription.length == 0
                    ) {
                      createAlert(
                        "ERROR",
                        "Error",
                        "Please add reason for leave."
                      );
                    } else {
                      setIsSubmitting(true);
                      // Create an object of formData
                      const formData = new FormData();

                      // Update the formData object

                      // Details of the uploaded file

                      // Request made to the backend api
                      // Send formData object

                      const config = {
                        headers: {
                          Authorization: state.auth.token,
                          "X-CSRFToken": state.auth.csrfToken,
                          // "Content-Type": "application/json",
                        },
                      };

                      formData.append("email", state.auth.user.email);
                      formData.append("id", makeid(5));

                      formData.append("leave_subject", leaveSubject);
                      formData.append("leave_description", leaveDescription);
                      formData.append(
                        "date",
                        moment(selectedDate).format("DD-MM-YYYY")
                      );

                      axios
                        .post(API_URL + "/add_leave_request", formData, config)
                        .then(() => {
                          getData();
                          setIsSubmitting(false);
                          createAlert(
                            "SUCCESS",
                            "Success.",
                            "Leave request submitted."
                          );
                        })
                        .catch((err) => {
                          createAlert("ERROR", "Error", "An error occured.");
                        });
                    }
                  }}
                  variant="contained"
                  component="span"
                >
                  <h4>Submit without application</h4>
                </Button>
              </div>
              <br></br>
              {isSubmitting ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </div>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_student) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                  marginTop: "46vh",
                }}
              >
                <CircularProgress />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar
                tabs={
                  <Grid container spacing={1.5}>
                    <Grid item md={4}>
                      {selectedPage == "NOTES" ? (
                        <Button
                          fullWidth
                          value={"NOTES"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "16px",
                              fontSize: "15px",
                            }}
                          >
                            NOTES
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value={"NOTES"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "16px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            NOTES
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {selectedPage == "ASSIGNMENTS" ? (
                        <Button
                          fullWidth
                          value="ASSIGNMENTS"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "16px",
                              fontSize: "15px",
                            }}
                          >
                            ASSIGNMENTS
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="ASSIGNMENTS"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "16px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            ASSIGNMENTS
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {selectedPage == "LEAVEREQUESTS" ? (
                        <Button
                          fullWidth
                          value="LEAVEREQUESTS"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "16px",
                              fontSize: "15px",
                            }}
                          >
                            LEAVE REQUESTS
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="LEAVEREQUESTS"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "16px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            LEAVE REQUESTS
                          </h3>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                }
              />
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    {fileData()}
                  </div>
                </Fade>
              </Modal>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open3}
                onClose={() => setOpen3(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open3}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton size="small">
                        <CloseIcon
                          onClick={() => setOpen3(false)}
                          style={{ color: "#3182bd" }}
                        />
                      </IconButton>
                    </div>
                    <div style={{ marginTop: "-25px" }}>
                      <h3>How would you like to proceed?</h3>
                    </div>

                    <br />
                    {isSubmitting ? (
                      <CircularProgress />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <a
                          style={{ color: "white", textDecoration: "none" }}
                          target="_blank"
                          href={assignmentLink} rel="noreferrer"
                        >
                          <Button
                            style={{ height: "30px" }}
                            variant="contained"
                          >
                            Download
                          </Button>
                        </a>

                        {isSubmitted ? (
                          <Button
                            style={{ marginLeft: "10px", height: "30px" }}
                            variant="disabled"
                            component="label"
                            disabled
                          >
                            Submitted{" "}
                            <CheckCircleIcon style={{ marginLeft: "8px" }} />
                          </Button>
                        ) : (
                          <label
                            style={{ width: "100%" }}
                            htmlFor="contained-button-file"
                          >
                            <input
                              style={{ display: "none" }}
                              id="contained-button-file"
                              type="file"
                              onChange={(e) => onFileChange(e)}
                            />

                            <Button
                              style={{
                                width: "100%",
                                fontSize: "15px",
                                height: "35px",
                                marginTop: "-1px",
                                backgroundColor: "#3182bd",
                              }}
                              variant="contained"
                              component="span"
                            >
                              Submit
                            </Button>
                          </label>
                        )}
                      </div>
                    )}
                  </div>
                </Fade>
              </Modal>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open2}
                onClose={() => setOpen2(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open2}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen2(false)}
                            style={{ color: "#3182bd" }}
                          />
                        </IconButton>
                      </div>
                      <h3>Delete this item?</h3>
                      <span>
                        This action cannot be reversed. It will be deleted
                        forever.
                      </span>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => deleteItem()}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          Yes
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => setOpen2(false)}
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <div
                style={{
                  margin: "65px 15px 20px 85px",
                  backgroundColor: "white",
                }}
              >
                {selectedPageContent()}
              </div>
            </Fragment>
          );
        }
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Student");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Classes;
