import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircularProgress from "@mui/material/CircularProgress";
import ButtonBase from "@mui/material/ButtonBase";
import moment from "moment";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Fab from "@mui/material/Fab";

import GetAppIcon from "@mui/icons-material/GetApp";
import { borderRadius } from "@mui/system";
import { loadUser } from "../actions/auth";

function Results() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  const [results, setResults] = useState([]);

  const [selectedPage, setSelectedPage] = useState("RESULTS");
  const [index, setIndex] = useState("");

  function handlePageChange(index) {
    setIndex(index);
    setSelectedPage("RESULT");
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      setResults(JSON.parse(state.auth.user.results));
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    dispatch(loadUser());
    setTimeout(() => {
      setResults(JSON.parse(state.auth.user.results));
    }, 5000);
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function selectedPageContent() {
    if (selectedPage == "RESULT") {
      return (
        <Fragment>
          <div
            style={{
              marginTop: "-9px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              fontFamily: "Bahnschrift",
              fontWeight: "lighter",
            }}
          >
            <Button
              fullWidth
              style={{
                backgroundColor: "#3182bd",
                color: "white",
                width: "5%",
                height: "35px",
                borderTopLeftRadius: "6px",
                borderBottomLeftRadius: "6px",
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
              }}
              onClick={() => setSelectedPage("RESULTS")}
            >
              <ArrowBackIcon style={{ fontSize: "25px" }} />
            </Button>
            <div
              textAlign="center"
              style={{
                color: "#084594",
                backgroundColor: "#d7ecff",
                width: "95%",
                height: "35px",
                padding: "3px 15px 0px 25px",
                alignItems: "center",
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                borderTopRightRadius: "6px",
                borderBottomRightRadius: "6px",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "18px",
              }}
            >
              <span>{results[index]["test"]}</span>
              <span>{results[index]["semester"]}</span>
              <span>{results[index]["percent"]}%</span>
              <span>
                {results[index]["marks_obtained"]} /
                {" " + results[index]["marks_total"]}
              </span>
              {moment(results[index]["start_date"], "DD-MM-YYYY").format(
                "Do MMMM YYYY"
              ) + "  "}{" "}
              to
              {"  " +
                moment(results[index]["end_date"], "DD-MM-YYYY").format(
                  "Do MMMM YYYY"
                )}
            </div>
          </div>

          <Box p={1}>
            <Grid
              style={{
                width: "100%",
                height: "40px",
                marginLeft: "-7px",
                alignItems: "center",
                borderRadius: "6px",
                fontSize: "18px",
                marginTop: "-20px",
                fontFamily: "Bahnschrift",
                justifyContent: "space-between",
              }}
              container
              spacing={5}
            >
              <Grid item md={4}>
                <div
                  style={{
                    marginLeft: "-40px",
                  }}
                >
                  Subject
                </div>
              </Grid>
              <Grid item justifyContent="center" md={6}>
                <div style={{ marginLeft: "-20px" }}>Marks</div>
              </Grid>
              <Grid item justifyContent="center" md={2}>
                <div
                  style={{
                    marginLeft: "0px",
                  }}
                >
                  Date
                </div>
              </Grid>
            </Grid>
            {results[index]["results"].map((current_result, index) => (
              <Grid
                container
                justifyContent="space-between"
                spacing={5}
                style={{ marginTop: "10px", maxHeight: "30px" }}
              >
                <Grid item md={4}>
                  <Box
                    justify="center"
                    style={{
                      color: "black",
                    }}
                  >
                    <b>
                      <p
                        style={{
                          marginTop: "-10px",
                          marginLeft: "-5px",
                          color: "#084594",
                        }}
                      >
                        {Object.keys(current_result)[0]}
                      </p>
                    </b>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box
                    style={{
                      color: "black",
                    }}
                  >
                    <b>
                      <p
                        style={{
                          marginTop: "-10px",
                          color: "#084594",
                        }}
                      >
                        {
                          current_result[Object.keys(current_result)[0]][
                            "marks_obtained"
                          ]
                        }{" "}
                        <span style={{ color: "#3182bd" }}> / </span>
                        {
                          current_result[Object.keys(current_result)[0]][
                            "marks_total"
                          ]
                        }
                      </p>
                    </b>
                  </Box>
                </Grid>
                <Grid item md={2}>
                  <Box
                    style={{
                      color: "black",
                    }}
                  >
                    <b>
                      <p
                        style={{
                          marginTop: "-10px",
                          fontWeight: "lighter",
                        }}
                      >
                        {moment(
                          current_result[Object.keys(current_result)[0]][
                            "date"
                          ],
                          "DD-MM-YYYY"
                        ).format("Do MMMM YYYY")}
                      </p>
                    </b>
                  </Box>
                </Grid>
              </Grid>
            ))}
            <Box
              style={{
                backgroundColor: "black",
                marginTop: "40px",
                marginLeft: "-35px",
                backgroundColor: "#d7ecff",
                padding: "10px 10px 10px 30px",
                width: "105.5%",
                maxHeight: "40px",
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                spacing={5}
                style={{ marginTop: "-58px" }}
              >
                <Grid item md={4}>
                  <Box
                    justify="center"
                    style={{
                      color: "black",
                    }}
                  >
                    <b>
                      <p style={{}}>Total Marks </p>
                    </b>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box
                    style={{
                      color: "black",
                    }}
                  >
                    <b>
                      <p style={{ marginLeft: "-3px" }}>
                        {results[index]["marks_obtained"]} /
                        {" " + results[index]["marks_total"]}
                      </p>
                    </b>
                  </Box>
                </Grid>
                <Grid item md={2}>
                  <Box
                    style={{
                      color: "black",
                    }}
                  >
                    <b>
                      <p style={{ marginLeft: "-13px" }}>
                        {results[index]["percent"]}%
                      </p>
                    </b>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box
              style={{
                width: "100%",
                justifyContent: "space-between",
                display: "flex",
                marginTop: "20px",
              }}
            >
              <div style={{ width: "100px" }}></div>
              <Box
                textAlign="center"
                width={200}
                height={20}
                p={2}
                style={{
                  color: "#31a354",
                }}
                borderRadius="6px"
              >
                <b>
                  <p
                    style={{
                      marginTop: "-12px",
                      fontSize: "20px",
                      fontFamily: "Bahnschrift",
                      letterSpacing: "3px",
                      marginLeft: "-202px",
                    }}
                  >
                    {results[index]["result"]}
                  </p>
                </b>
              </Box>

              <Box
                style={{
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "0px",
                }}
              >
                <Button
                  textAlign="center"
                  style={{
                    backgroundColor: "#3182bd",
                    display: "flex",
                    color: "black",
                    padding: "5px",
                    height: "35px",
                    width: "150px",
                    borderRadius: "6px",
                    color: "white",

                    fontFamily: "Bahnschrift",
                  }}
                  borderColor="#dedede"
                >
                  <GetAppIcon
                    style={{
                      marginTop: "0px",
                      marginLeft: "-5px",
                    }}
                  />
                  <b
                    style={{
                      textAlign: "center",
                      margin: "3px 0px 0px 3px",
                      lineHeight: "14px",
                      fontWeight: "lighter",
                    }}
                  >
                    Download {"\n"} PDF
                  </b>
                </Button>
                <br></br>
                <br></br>
              </Box>
            </Box>
          </Box>
        </Fragment>
      );
    } else {
      return (
        <Grid container spacing={1.5} style={{ marginTop: "-25px" }}>
          {results.length != 0 ? (
            results.map((result, index) => (
              <Grid item md={3}>
                <Card
                  style={{
                    minHeight: "100%",
                    backgroundColor: "white",
                    boxShadow: "none",
                  }}
                  onClick={(e) => {
                    handlePageChange(index);
                  }}
                >
                  <CardActionArea
                    sx={[
                      {
                        borderRadius: "6px",
                        border: "1.5px solid #d7ecff",
                        transition: "background-color 0.5s, border-color 0.5s",
                      },
                      (theme) => ({
                        "&:hover": {
                          backgroundColor: "#ebf5ff",
                          borderColor: "#3182bd",
                        },
                        ".MuiCardActionArea-focusHighlight": {
                          background: "transparent",
                        },

                        "&& .MuiTouchRipple-child": {
                          backgroundColor: "#084594",
                        },
                      }),
                    ]}
                    style={{ padding: "8px 7px 12px 13px" }}
                  >
                    <h2
                      style={{
                        marginTop: "0px",
                        color: "#084594",

                        fontFamily: "Lato-Bold",
                      }}
                    >
                      {result["test"]}
                    </h2>

                    <div
                      style={{
                        position: "relative",
                        bottom: "53px",
                        left: "16.5vw",
                        fontFamily: "Bahnschrift",
                        height: "30px",
                        width: "55px",
                        backgroundColor: "#3182bd",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        color: "white",
                        borderRadius: "5px",
                      }}
                    >
                      <span style={{ marginTop: "6px" }}>
                        {result["percent"].split(".")[0]}%
                      </span>
                    </div>
                    <p
                      style={{
                        margin: "-47px 0px 15px 1px",
                        fontSize: "14px",
                        fontFamily: "Lato-Regular",
                        color: "#3182bd",
                      }}
                    >
                      {result["semester"]}
                    </p>

                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Lato-Bold",
                          color: "#084594",
                        }}
                      >
                        Start{" "}
                      </span>
                      <span
                        style={{
                          margin: "0px 0px 0px 6px",
                          fontSize: "14px",
                          fontFamily: "Lato-Regular",
                          color: "#3182bd",
                        }}
                      >
                        {moment(result["start_date"], "DD-MM-YYYY").format(
                          "Do MMMM, YYYY"
                        )}
                      </span>
                      <div style={{ display: "flex" }}>
                        <span
                          style={{
                            color: "#084594",
                            fontFamily: "Lato-Bold",
                          }}
                        >
                          End{" "}
                        </span>
                        <span
                          style={{
                            margin: "0px 0px 0px 16px",
                            fontSize: "14px",
                            fontFamily: "Lato-Regular",
                            color: "#3182bd",
                          }}
                        >
                          {moment(result["end_date"], "DD-MM-YYYY").format(
                            "Do MMMM, YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </CardActionArea>
                </Card>
              </Grid>
            ))
          ) : (
            <div
              style={{
                marginTop: "200px",
                height: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Fab
                  size="large"
                  style={{
                    border: "3px solid #aaa",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  }}
                  aria-label="add"
                >
                  <PriorityHighIcon
                    style={{
                      color: "grey",
                    }}
                  />
                </Fab>
                <br />
                <h4 style={{ color: "grey" }}>No results uploaded.</h4>
              </div>
            </div>
          )}
        </Grid>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_student) {
        if (moment(state.auth.expiry).utc().isAfter(moment())) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  margin: "80px 20px 20px 90px",
                  backgroundColor: "white",
                }}
              >
                <div
                  style={{
                    marginTop: "20px",
                  }}
                >
                  {selectedPageContent()}
                </div>
              </div>
            </Fragment>
          );
        } else {
          return <Navigate to="/expired" />;
        }
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Student");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Results;
